.home-wrapper{
    margin-top: 4rem;
}


.pt-popover-content {
    background: white;
}

.pt-popover-arrow-fill {
    fill: white;
}


.tooltip > .tooltip-inner {
    background-color: #D4EAF3;
    color: black;
    border: 1px solid #008696;
  }
  
.tooltip.show {
	opacity: 1;
}

.isrp-link{
    color:#A50097;
}

.isrp-link:hover{
    color:rgb(255, 0, 234)
}

.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before, .bs-tooltip-top .tooltip-arrow::before {
	top: -1px;
	border-width: .4rem .4rem 0;
	border-top-color: #008696;
}

.nav-link{
    
    color:#353445 !important;
    color:white !important;
    font-weight: bold;
}

.nav-link:hover{
    color:#A50097 !important;
}

.accordion-button{
    color:white;
    background-color: #6dadc4;
}

.accordion-item{
    border: 1px solid rgba(255, 255, 255, 0.39);
    background-color: #6dadc4;
}

.accordion-button:not(.collapsed) {
	color: #fff;
	background-color: #6dadc4;
	box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.accordion-button::after {
    margin: auto 0 auto auto;
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    width: 3rem;
    height: 3rem;
    background-size: 3rem;
}


.accordion-button:focus {
	border-color: #ffffff;
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, 0.25);

}

.navbar-brand{
    font-weight: bold;
    
    color:#353445 !important;
    color:white !important;
    font-size: 2rem !important;
}

.hero-text{
    text-align: center;
    color: #008696;
}

.icon-container{
    width: 90%;
}

.icon-container:hover{
    cursor: pointer;
}

.section{
    scroll-margin-top: 4em;
    width: 100%;
    color:white;
    min-height: 100vh;
}

#produits{
    color:#008696;
    background-color: #D4EAF3;
}

#quisommesnous{
    background-color: #6dadc4;
}

#donnees{
    background-color:#6dadc4;
}

#nouvelles{
    color:#008696;
}

#infolettre{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color:#94d9e0;
    
    
}

#contact{
    color:#008696;
    background-color: #D4EAF3;
    height: 100vh;
}

@keyframes zoomIn {
    0%{
        transform: scale(0.3);
    }
    100%{
        transform: scale(1);
    }
    
}

.recherche-btn{
    background-color: #94d9e0;
    color: white;
}

.recherche-btn::placeholder{
    color: #f8f9fa;
}

.form-rounded {
    background-color: #9ed9df;
    color:white;
	border-radius: 1rem !important;
    border: 2px solid #fff;
}

.hero-anim{
    animation: 0.8s ease-out 0s 1 zoomIn;
}

.abv-btn:hover{
    background-color: #96dce4;
    color:white;

}


.hero{
    font-size: 6rem;
    font-weight: bold;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    position: relative;

    background-position: 50% -200%;
    background-size: 105% 100%;
}


.navbar{
    background:#58B6C0 !important;
    z-index: 5000;
}

.article{
    z-index: 5001;
}
.navbar-toggler-icon{
    color:white;
}

.qsm-accordion{
    width: 100%;
}


@media only screen and (min-width:992px){
    .navbar-container{
        width: 100% !important;
    }
}

@media only screen and (min-width:778px){
    .qsm-accordion{
        width: 80%;
    }
}



@media only screen and (min-width:1150px){
    .navbar-expand-custom {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-custom .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-custom .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-custom .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-custom > .container {
        flex-wrap: nowrap;
    }
    .navbar-expand-custom .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
    }
    .navbar-expand-custom .navbar-toggler {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .display-1 {
      font-size: 6rem;
    }
    .qsm-accordion{
        width: 60%;
    }
  }
