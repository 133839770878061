@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import 'bootstrap/dist/css/bootstrap.min.css';
body {
  height: 100%;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer a{
  color:black;
}

footer a:hover{
  color: rgb(54, 54, 54);
}

#root{
  height: 100%;
}

a:hover {
  color: inherit;
  text-decoration: none;
  color: white;
}

a:focus{
  outline: none;
  text-decoration: none;
}

.loader{
  margin: 100px auto;
}

.card-title{
  font-size: 30px;
  text-align: center;
}

.auth-form{
  margin-top: 10px;
}

.employee-form{
  margin: 5% 20% 0 20%;
}

.form-button{
  margin: 0 auto;
  max-width: 60%;
}

.user-details {
  display: flex;
  justify-content: space-between;
}

.user-actions{
  display: flex;
  flex-direction: column;
}


.wrapper{
  margin: 0 auto;
  max-width: 400px;
  padding-top: 10%;
}

.employee-cards{
  text-align: center;
  margin:0 auto;
  max-width: 800px;
}

.cms-item-wrapper{
  padding: 2% 20% 0% 20%;
}

.cms-wrapper{
  padding-top:50px;
  position: relative;
  height: 100%;
}

.footer-container{
  max-width: 960px;
  width: 100%;

}

.card{
  transition: 500ms;
}


.carousel-item>.card{
  border:none;
}

.carousel-dark .carousel-indicators [data-bs-target] {
	background-color: white;
}

.card-deck .card {
	display: -ms-flexbox;
	display: flex;
	-ms-flex: 1 0 0%;
	flex: 1 0 0%;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-right: 15px;
	margin-bottom: 0;
	margin-left: 15px;
}

.card-deck{
  display: flex;
  flex-flow:row wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-img{
  height:400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bp3-navbar{
  z-index: 50;
}

.container{
  max-width: 1320px;
}

.deck-container{
  max-width: 960px;
}

.disabled-menu{
  pointer-events: none;
  background: #919191;

}


.disabled-submenu{
  pointer-events: none;
  background: #919191 !important;

}

.download-card{
  min-width: 25rem;
  max-width: 28rem;
}

.modal-backdrop{
  z-index: 850;
}

.text-editor {
	height: 90%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
