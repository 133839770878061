.carousel-caption{
    filter:drop-shadow(0px 0px 0.5rem rgb(0, 0, 0));
}




.carousel{
    transition: 500ms;
    width: 50rem;
}

.carousel-hover:hover{
    cursor: pointer;
    transition: 500ms;
    filter: grayscale(80%);
}

.carousel-hover:hover .carousel-caption{
    text-decoration: underline;
}

@media only screen and (max-width: 1600px) {
    .carousel {
        width: 40rem;
    }
}

.overlay{
    background-image:
    linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(133, 133, 133, 0.73));
}